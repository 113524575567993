<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <ParentForm ref="form" />
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <router-link class="btn btn-white router-link-active" :to="{ name: 'SettingEvent' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-blue" :handle-submit="register" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import nav from '@/mixins/nav/setting';
import ParentForm from '../../Form/Parent.vue';

export default {
  name: 'SettingEventParentRegister',
  data: function () {
    return {
      pageName: '設定',
    };
  },
  components: {
    ParentForm,
  },
  mixins: [nav],
  methods: {
    register: function () {
      this.$refs.form.handleSubmit();
    },
  },
};
</script>
